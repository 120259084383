import React,{useEffect,useState} from 'react'
import Layout from '../../components/Layout/Layout'
import './Auth.css'
import { useDispatch } from "react-redux";
import { useHistory,withRouter } from "react-router-dom";
import { Authenticator, SignIn } from 'aws-amplify-react';
import {Auth as AmpAuth} from 'aws-amplify'
import {Analytics} from 'aws-amplify'
import {DASHBOARD_BUTTON} from '../../analytics/events'

const Auth = () => {

    const history = useHistory()
    const goToDashboard = () => history.push('/dashboards');
    const [authState,setAuthState] = useState(false);
    const dispatch = useDispatch();
    
    const dispatchHandler = (data)=>{
        dispatch({type: "login",payload: data})
    }
    // useEffect(()=>{

    // },[authState])
    const authStateChange = (authState) => {
        if(authState == 'signedIn'){            
            AmpAuth.currentAuthenticatedUser().then(user => {
                console.log(user.attributes)
                localStorage.setItem('user', JSON.stringify(user.attributes))
                dispatchHandler({status:true,role:'admin'})
                goToDashboard()
                // Analytics.record({name:'login',attributes:{ activity: 'login', user: user.attributes.email, phone_number: user.attributes.email}})
                // .then(()=>{
                //     // dispatchHandler({status:true,role:'admin'})
                //     // goToDashboard()
                // })
            })  
        }
    }

    const data = (
        <Authenticator hideDefault={true}     onStateChange={authStateChange} 
        >
        <SignIn />
        {/* <MyCustomSignUp override={'SignUp'}/>  */}
      </Authenticator>
    )
    return(

        <Layout content={data}/>
    )
}
// export default Auth
export default withRouter(Auth)