import React from 'react'
import {withAuthenticator} from 'aws-amplify-react'
import { useHistory,withRouter } from "react-router-dom";
import Auth from '../../Auth/Auth'

const AdminHome = () => {
    return (
        <div>Hello Admin</div>
    )
}


export default withRouter(withAuthenticator(AdminHome))