import React from 'react'
import Layout from '../../../components/Layout/Layout'
import { useState, useEffect } from 'react'
import {API} from 'aws-amplify'
import {withAuthenticator} from 'aws-amplify-react'
import {Analytics} from 'aws-amplify'
import {DASHBOARD_BUTTON} from '../../../analytics/events'
// abhibus_ml'4ee72b3d-49d5-4f7e-8874-e696e9d7db64'
// abhibus_analytics '24861723-aff8-40a9-83fa-546649dae5e5'
// app_ml '7c7575e4-6053-4420-a3a4-11afafcfa078'
// urc_ml '351c3f47-150a-45fb-9597-6e644570c5c9'
const ViewDashboard = (props) => {
    const { match } = props;
    let {id} = match.params;
    // let dashboardName = props.location.dashboardName
    const [dashboardName, setDashboardName] = useState('')
    const [dashboard, setDashboard] = useState({})
    const [dashboardTwo,setDashboardTwo] = useState({})
    const dashIds = [['4ee72b3d-49d5-4f7e-8874-e696e9d7db64','24861723-aff8-40a9-83fa-546649dae5e5'],
                ['f22d85e7-34b4-4232-bb44-24bfe8f83a4c'],['7c7575e4-6053-4420-a3a4-11afafcfa078']]        
    useEffect(() =>{          
        
        let dashId
        switch(parseInt(id)){
            case 1:
                dashId = dashIds[0][0]
                setDashboardName('eBusiness – Large Online Bus Ticketing Platform')
                API.get('apiDemandForecast', '/dashboards/'+dashIds[0][1]).then(data => {
                    console.log({data})
                    setDashboardTwo({EmbedUrl: data.EmbedUrl})
                    console.log(data.EmbedUrl)
                })
                .catch(err => console.log({err}))  
                break
            case 2:
                dashId = dashIds[1][0]
                setDashboardName('Branded Consumer Goods Enterprise')
                break
            case 3:
                dashId = dashIds[2][0]
                setDashboardName('Branded Paper Enterprise')
                break                                            
        }
        API.get('apiDemandForecast', '/dashboards/'+dashId).then(data => {
            console.log({data})
            setDashboard({EmbedUrl: data.EmbedUrl})
            console.log(data.EmbedUrl)
        })
        .catch(err => console.log({err}))    
    },[]);   
    useEffect(()=>{
        if(dashboardName != ''){
            let user = JSON.parse(localStorage.getItem('user'))

            Analytics.record({name:'view_dashboard',attributes:{activity:'view_dashboard',type:'ml', user: user.email, dashboard_name: dashboardName}})
            .then(()=>{
                console.log('sent dashboard')
            })    
        }
    },[dashboardName])

    const dashboardTypeHandler = (type) =>{
        let user = JSON.parse(localStorage.getItem('user'))
        Analytics.record({name:'view_dashboard',attributes:{activity: 'view_dashboard',type:type, user: user.email, dashboard_name: dashboardName}})
        .then(()=>{
            console.log('sent dashboard')
        })  
    }
    let data = (        
        // <div className="row mt-3">
        //     {
        //         dashboard.EmbedUrl ?
        //         <div className="col">
        //             <div className="row">
        //                 <div className="col" style={{textAlign: 'center'}}>
        //                     <h3>{dashboardName}</h3>
        //                 </div>
        //             </div>
        //             <div>
        //                 <div className="col" >
        //                     <iframe src={dashboard.EmbedUrl} style={{minHeight: '500px',minWidth: '100%',border: 'ridge'}}></iframe>
        //                 </div>
        //             </div>
        //         </div>              : ''         
        //     }
        // </div>

        <React.Fragment>
            <div className="row mt-1">
                <div className="col" style={{textAlign: 'center'}}>
                    <h4>{dashboardName}</h4>
                </div>
            </div>
            <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a  onClick={() => dashboardTypeHandler('ml')} className="nav-item nav-link active shadow" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">
                    <img data-toggle="tooltip" title="AI/ML Forecasting" height="30" src="/assets/images/viewDashboard/ai.png" className="mr-3" alt="..."></img>
                </a>
                <a onClick={() => dashboardTypeHandler('analytics')} className="nav-item nav-link shadow" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">
                    <img data-toggle="tooltip" title="BI and analytics" height="30" src="/assets/images/viewDashboard/analytics.png" className="mr-3" alt="..."></img>
                </a>
            </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <iframe src={dashboard.EmbedUrl} style={{minHeight: '500px',minWidth: '100%',border: 'ridge'}}></iframe> 
                </div>
                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                <iframe src={dashboardTwo.EmbedUrl} style={{minHeight: '500px',minWidth: '100%',border: 'ridge'}}></iframe>
                </div>
            </div>
        </React.Fragment>
    )
    return(
        <Layout content={data}/>
    )
}

export default withAuthenticator(ViewDashboard)