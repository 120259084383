import React from 'react'
import CreateUser from './CreateUser/CreateUser'
import AdminHome from './AdminHome/AdminHome'


const Admin = () => {
    return (
        <AdminHome />
        // <CreateUser />
    )
}

export default Admin