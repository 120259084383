import React from 'react'
import { useState, useEffect } from 'react'
import Layout from '../../components/Layout/Layout'
import './Dashboard.css'
import {Link,withRouter,useHistory} from 'react-router-dom'
import {API} from 'aws-amplify'
import {withAuthenticator} from 'aws-amplify-react'
import Auth from '../Auth/Auth'
import {Analytics} from 'aws-amplify'
import {Auth as AmpAuth} from 'aws-amplify'
import {DASHBOARD_BUTTON} from '../../analytics/events'

const Dashboard = () => { 
    const [dashboards, setDashboards] = useState({})
    
    const history = useHistory()    
    const goToViewDashboard = (id) => history.push('/dashboards/'+id);

    // useEffect(() =>{        
        // API.get('apiDemandForecast', '/dashboards').then(data => {
        //     console.log({data})
        //     setDashboards(data.DashboardSummaryList)
        // })
        // .catch(err => console.log({err}))
    // },[]);    

    const displayDashboards = dashboard => {        
        if(dashboard.DashboardId != '3c938aff-9994-4541-ab98-b7d340903b5b') {    
            return (          
                <li style={{backgroundColor:'aliceblue'}} className="list-group-item" key={dashboard.DashboardId}  >      
                    <Link style={{color: 'rgb(231, 131, 40)'}} to={{pathname:`/dashboards/${dashboard.DashboardId}`,dashboardName:dashboard.Name}} >{dashboard.Name}</Link>
                </li>
            )
        }
    }
    const clickHandler = (dashboardNumber) => {
        goToViewDashboard(dashboardNumber)
        // switch (dashbaordNumber){
        //     case 1:
        //         goToViewDashboard()
        //         break
        //     case 2:
        //         alert('bye')
        //         break
        //     case 3:
        //         alert('gu')
        //         break
        // }
    }
    const recordEvent = () => {
        console.log('clicked')
    
        AmpAuth.currentAuthenticatedUser().then(user => {
            console.log(user.attributes)
            // localStorage.getItem('user')
            Analytics.record({name:DASHBOARD_BUTTON,attributes:{ activity: 'login', user: user.attributes.email, phone_number: user.attributes.email}})
                .then(()=> {
                    console.log('event sent')
                })
        }) 
    }
    
    const data = (
            // <div className="row justify-content-md-center">
            //     <div className="col text-center pl-4 pt-2 pr-5 dashboard-heading mt-2">
            //         <h2>Dashboard</h2>
            //     </div>
            //     <div className="w-100"></div>
            //     { dashboards.length ? 
            //         <div className="col-sm-6 mt-3">

            //                 {/* <h3 className="card-title" style={{color: 'lightgray'}}>Dashboards</h3> */}
            //                 <ul className="list-group">
            //                     {dashboards.map(displayDashboards)}
            //                 </ul>
            //         </div>
            //     : ''}
            // </div>
            
            <div className="container w-75 text-center mt-5">     
                  {/* <button onClick={() => recordEvent()}>Record Event</button>            */}
                <h3>Use Cases</h3>
            <div className="row row-cols-1 row-cols-md-3  mt-3" >
                <div className="col mb-4 " style={{cursor:'pointer'}} onClick={()=>clickHandler(1)}>
                <div className="card h-80 rounded shadow">
                    <img height="200" src="/assets/images/dashboard/transport_new.png" className="card-img-top" alt="..."></img>
                    <div className="card-body" style={{backgroundColor: 'rgb(41, 187, 231)',color: 'white'}}>
                    <p className="card-text">Transport Aggregator (Passenger Demand Forecasting)</p>
                    </div>
                </div>
                </div>
                <div className="col mb-4" style={{cursor:'pointer'}} onClick={()=>clickHandler(2)}>
                <div className="card h-80 rounded shadow">
                    <img height="200" src="/assets/images/dashboard/manufacturer_new.png" className="card-img-top" alt="..."></img>
                    <div className="card-body" style={{backgroundColor: 'rgb(41, 187, 231)',color: 'white'}}>
                    <p className="card-text" >Manufacturer Of Consumer Goods (Sales Demand Forecasting)</p>
                    </div>
                </div>
                </div>
                <div className="col mb-4" style={{cursor:'pointer'}} onClick={()=>clickHandler(3)}>
                <div className="card h-80 rounded shadow">
                    <img height="200" src="/assets/images/dashboard/paper.jpg" className="card-img-top" alt="..."></img>
                    <div className="card-body" style={{backgroundColor: 'rgb(41, 187, 231)',color: 'white'}}>
                    <p className="card-text" >Manufacturer Of Paper And Pulp (Product Demand Forecasting)</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        )
    return(
        <Layout content={data}/>
    )
}
// export default withRouter(Dashboard)
export default withRouter(withAuthenticator(Dashboard,{includeGreetings:false,authenticatorComponents:[<Auth/>]}))