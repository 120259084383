import React from 'react';
import './App.css';
import Pages from './Pages'
import { Provider } from "react-redux";
import { createStore } from "redux";
import RootReducer from "./reducers/RootReducer";

const state = createStore(RootReducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

function App() {


  return (
    <Provider store={state}>
      <Pages />
    </Provider>
  );
}

export default App;