import React from 'react'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import './Layout.css'
// import Drawer from './Drawer/Drawer'

const Layout = (props) => {
    return (
        <React.Fragment>
            <Header />
            <div className={props.homepageBackground && props.homepageBackground}>
                {props.content}
            </div>      
            {/* <Drawer/>        */}
            {/* <Footer /> */}
        </React.Fragment>
    )
}

export default Layout