import React from 'react';
import { BrowserRouter as Router, Switch, Route,Redirect } from 'react-router-dom';
import Homepage from './Homepage/Homepage';
import Auth from './Auth/Auth';
import Dashboard from './Dashboard/Dashboard';
import ViewDashboard from './Dashboard/ViewDashboard/ViewDashboard.js'
const createHistory = require("history").createBrowserHistory

const history = createHistory();
const Pages = () => {
    
    return (
        <Router history={history}>
            <Switch>
                {/* <Route exact path="/">
                    <Homepage />
                </Route> */}
                {/* <Route path="/auth">
                    <Auth />
                </Route>                 */}
                <Route exact path='/'>
                    <Auth />
                </Route>
                <Route path="/dashboards" exact>
                    <Dashboard />
                </Route>
                {/* <Route path="/home" exact>
                    <Dashboard />
                </Route> */}
                <Route path="/dashboards/:id" exact component={ViewDashboard}></Route>
                <Redirect to="/" />

            </Switch>
        </Router>
    );
};

export default Pages;