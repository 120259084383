import React,{useEffect} from 'react'
import {NavLink,useHistory} from 'react-router-dom'
import { useSelector,useDispatch } from "react-redux";
import './Header.css'
import {Auth as AmpAuth} from 'aws-amplify'
import {Analytics} from 'aws-amplify'
import {DASHBOARD_BUTTON} from '../../../analytics/events'

const Header = () => {

    const loginState = useSelector(state => state);
    const dispatch = useDispatch();

    const dispatchHandler = (role)=>{
        dispatch({type: "login",payload: {role}})
    }
    const history = useHistory()
    const goToHome = () => history.push('/');
    
    const signout = () => {
        AmpAuth.signOut({ global: true })
        .then(data =>{            
            Analytics.record({
                name:'logout',
                attributes:{
                    activity: 'logout', user: JSON.parse(localStorage.getItem("user"))
                }
            })
            .then(()=> {
                localStorage.removeItem('user')
                console.log(data)
                dispatchHandler({status:true,role:null}) 
                goToHome()
            })            
        })
        .catch(err => console.log(err));            
    }
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light header">
            {/* <a className="navbar-brand" href="#" style={{padding:'none'}}>
                <img src="/assets/images/logo_new_7.png" width="auto" height="50" alt="1CloudHub" className="default-logo"></img>
            </a> */}
            <a className="navbar-brand second-logo" href="#" style={{padding:'none'}}>
                <img src="/assets/images/logo_new_12.png" width="auto" height="40" alt="1CloudHub" className="default-logo" ></img>
            </a>
            <span style={{marginRight:'4px', marginTop: '16px'}}>AI/ML solutions</span>            
            <a className="navbar-brand" href="#" style={{padding:'none'}}>
                <img src="/assets/images/n_sights_logo_with_powered_by.png" width="auto" height="50" alt="1CloudHub" className="default-logo"></img>
            </a>
            {/* <a className="navbar-brand" href="#" style={{padding:'none'}}>
                <img src="/assets/images/cropped_logo_black.png" width="120" height="35" alt="1CloudHub" className="default-logo"></img>
            </a>                         */}
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                <ul className="navbar-nav justify-content-end">
                {/* <li className="nav-item">
                    <NavLink to="/" className="nav-link" activeClassName='is-active'>Home</NavLink>
                </li>    */}
                {
                    loginState.role == 'admin'?
                    <li className="nav-item">
                        <NavLink to="/dashboards" exact className="nav-link" activeClassName='is-active'>Home</NavLink>
                    </li>                    
                    : ''
                }              
                <li className="nav-item">
                    {loginState.login ? <span className="nav-link" onClick={signout} style={{cursor : 'pointer'}}>Logout</span> :
                    ''// <NavLink to="/auth" className="nav-link" activeClassName='is-active'>Login</NavLink>
                    }
                </li>

                {/* <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Dropdown link
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <a className="dropdown-item" href="#">Action</a>
                    <a className="dropdown-item" href="#">Another action</a>
                    <a className="dropdown-item" href="#">Something else here</a>
                    </div>
                </li> */}
                </ul>
            </div>
        </nav>
    )
}

export default Header