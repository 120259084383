function RootReducer(state = {login: false,role:'none'}, action){
    switch (action.type) {
        case 'login':
            return {
                login: action.payload.status,
                role:action.payload.role
            };
        default:
        return false;
    }
}
 
export default RootReducer;