/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cloud_logic_custom": [
        {
            "name": "apiDemandForecast",
            "endpoint": "https://ij1kkgnv2e.execute-api.ap-south-1.amazonaws.com/master",
            "region": "ap-south-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-south-1:9559d86e-ba6a-424f-b042-f460c0625c08",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_gQITsb9d0",
    "aws_user_pools_web_client_id": "1kq7m5p6m0js38t10ep5g22bg4",
    "oauth": {},
    "aws_mobile_analytics_app_id": "084b88490068441899258ac2db5238df",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
