import React from 'react'
import Layout from '../../components/Layout/Layout'
import { useSelector } from "react-redux";
import Admin from '../Admin/Admin.js'
import Dashboard from '../Dashboard/Dashboard'
import {withRouter } from "react-router-dom";


// import { useDispatch } from "react-redux";

const Homepage = () => {
    const state = useSelector(state => state);
    
    // const dispatch = useDispatch();
    
    // const dispatchHandler = ()=>{
    //     dispatch({type: "login"})
    // }

    // const data = (
    //     <div onClick={dispatchHandler}>Hafdasf{loginState.login?'loggedin':'false'}</div>
    //     )
    let data 
    if(state.login){
        if(state.role == 'admin'){
            data = (
                <Admin />
                // <Dashboard />
            )
        }    
    }
    return(
        <Layout content={data} homepageBackground="homepage"/>
    )
}

export default withRouter(Homepage)